<template>
  <div class="">
    <b-card no-body class="">
      <!-- Le haut du tableau contenant les barre de recherche et bouton d'ajout de nouvelle taxe -->

      <b-row class="px-2">
        <!-- Per Page -->

        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center flex-wrap justify-content-start mb-1 mb-md-0 mt-1"
        >
          <label>Entrées</label>

          <v-select
            v-model="state.perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />

          <b-button
            variant="primary"
            @click="modeModal = 'e-add-new-article'"
            v-b-modal.e-add-new-article
          >
            <feather-icon icon="PlusIcon" class="mx-auto" />
            Nouvel article
          </b-button>

          <b-button variant="ouline-primary" :to="{ name: 'catalogue' }">
            <feather-icon icon="ShoppingBagIcon" class="mx-auto" />
            Catalogues
          </b-button>
        </b-col>

        <!-- Search -->

        <b-col cols="12" md="6" class="mt-1">
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="state.filtre"
                class="d-inline-block mr-1"
                placeholder="Rechercher par : nom d'article, type, prix valeur..."
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>

      <q-loader-table
        :success="state.success"
        :empty="state.empty"
        :warring="state.warring"
      />

      <b-table
        hover
        responsive
        primary-key="id"
        :per-page="state.perPage"
        :current-page="state.currentPage"
        :items="articles"
        :fields="tableColumns"
        :filter="state.filter"
        show-empty
        empty-text="Aucun article enregistré"
        class="bg-white text-left"
        v-if="state.success === true"
      >
        <template #cell(libelle)="data">
          <section class="d-flex align-items-center">
            <div class="">
              <img
                :class="'getImageArticle_' + data.item.id"
                style="width: 32px; height: 32px"
                :src="data.item.media"
                alt=""
              />
            </div>

            <div class="ml-50">
              <div class="d-flex flex-column">
                <span>{{ data.item.libelle }}</span>
              </div>
              <div style="font-size: 12px">
                <feather-icon icon="ChevronRightIcon" size="12" />
                {{
                  data.item.categorie.libelle
                    ? data.item.categorie.libelle
                    : data.item.categorie
                }}
              </div>
            </div>
          </section>
        </template>

        <template #cell(prix_vente)="data">
          <span>{{ formatter(parseInt(data.item.prix_vente)) }}</span>
        </template>

        <template #cell(Commande)="data">
          <span class="">
            {{ data.item.commande }}
            <span style="font-size: 11px">{{
              parseInt(data.item.commande) > 1 ? "Fois" : "Fois"
            }}</span>
          </span>
        </template>

        <!-- Bouton d'action -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap py-1">
            <feather-icon
              @click="updateArticle(data.item)"
              v-b-modal.e-update-article
              icon="Edit3Icon"
              size="16"
              class="cursor-pointer"
            />

            <feather-icon
              @click="destoryArticle(data.item.id)"
              icon="TrashIcon"
              class="ml-1 cursor-pointer"
              size="16"
            />

            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
              disabled
              class="hidden"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- <b-dropdown-item @click="editFac(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>
            </b-dropdown-item> -->
              <b-dropdown-item disabled>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50"> Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <!-- Les boutons de pagination -->

      <div class="mx-2 mb-2 pb-1">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"></span>
          </b-col>

          <!-- Pagination -->

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="state.currentPage"
              :total-rows="articles.length"
              :per-page="state.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <e-add-articles :categories="categories" :articles="articles" />
    <e-edit-articles
      :categories="categories"
      :articles="articles"
      :article="dataAraticle.data"
    />

    {{ __NEXT }}
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import { required, email } from "@validations";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import vSelect from "vue-select";

import URL from "@/views/pages/request";

import axios from "axios";

import { formatDate } from "@/@core/utils/filter";
import { toast_destory, toast_error, toast_loading, toast_sucess } from "@/utils/qToast";
import EAddArticles from "@/components/__partials/articles/eAddArticles.vue";
import moment from "moment";
import { reactive, ref, onMounted, toRef, computed } from "@vue/composition-api";
import QLoaderTable from "@/components/__partials/loaders/qLoaderTable.vue";
import qDeviseUtils from "../../../../utils/qDeviseUtils";
import { qDecrypted } from "@/services/qeCrypt";
import EEditArticles from "@/components/__partials/articles/eEditArticles.vue";

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BImg,
    BLink,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    EAddArticles,
    QLoaderTable,
    EEditArticles,
  },

  directives: {
    Ripple,
  },

  setup(props, { root }) {
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: "",
      success: false,
      empty: false,
      warring: false,
      loading: false,
    });
    const categories = ref([]);
    const articles = ref([]);
    const dataAraticle = reactive({
      data: {},
    });
    const tableColumns = reactive([
      {
        key: "libelle",
        label: "Libelle & categorie",
      },
      {
        key: "type",
        label: "Types",
      },
      {
        key: "qte_un_article",
        label: "prix de achat",
      },
      {
        key: "prix_vente",
        label: "prix de vente",
      },
      {
        key: "Commande",
        label: "Commandé",
      },
      {
        key: "created_at",
        label: "date d'ajout",
      },
      {
        key: "actions",
        label: "Actions",
      },
    ]);
    const perPageOptions = [30, 50, 100, 150, 250, 500];
    const qDevise = ref("");
    const GIVE = ref(false);

    const __NEXT = computed(() => {
      onRealTimeData(articles.value);
    });

    onMounted(async () => {
      document.title = "Articles - Ediqia";
      await getArticles();
      await getCategory();
      qDevise.value = qDeviseUtils.getDevis__user();
    });

    const onRealTimeData = async () => {
      if (GIVE.value === true) {
        articles.value.length === 0
          ? ((state.empty = true), (state.success = false))
          : ((state.success = true), (state.empty = false));
      }
    };

    // Get all category
    const getCategory = async () => {
      try {
        const { data } = await axios.get(URL.CATEGORIE_LIST);
        if (data) {
          categories.value = data;
          GIVE.value = true;
        }
      } catch (error) {
        console.log(error);
      }
    };

    // Get all articles
    const getArticles = async () => {
      try {
        const { data } = await axios.get(URL.ARTICLE_LIST);
        if (data) {
          console.log(data);
          for (let i = 0; i < data[0].length; i++) {
            const article = data[0][i];

            articles.value.unshift({
              id: article.id,
              libelle: article.libelle,
              type: article.type,
              prix_achat: article.prix_achat,
              prix_vente: article.prix_vente,
              commande: article.factures.length,
              categorie: article.parametre,
              media:
                article.media.length !== 0
                  ? article.media[0].original_url
                  : "https://via.placeholder.com/150/4B0082/FFFFFF/?text=Ediqia",
              created_at: moment(String(article.created_at)).format("DD-MM-YYYY"),
              created_at_time: moment(article.created_at).toDate().getTime().toString(),
            });

            articles.value.sort((x, y) => x.created_at_time < y.created_at_time);
          }
          articles.value.length === 0 ? (state.empty = true) : (state.success = true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    //Update data
    const newArticle = reactive({
      id: "",
      libelle: "",
      categorie: "",
      type: "",
      poids: "",
      unite: "",
      prix_achat: "",
      prix_vente: "",
      movie: "",
      file: null,
      description: "",
    });
    const optionUnite = ref(["Kg", "Unité", "Litre"]);
    const optionType = ref(["Service", "Produit"]);
    const addNewCategorie = ref(false);
    const categorieNewValue = ref("");
    const processFile2 = (event) => {
      newArticle.file = event.target.files;
    };
    const errorInput = reactive({
      path: "",
      message: "",
    });
    const loadingDelay = ref(5000);

    // Get
    const getFile = (event) => {
      newArticle.file = event.target.files[0];
    };

    const updateArticle = (data) => {
      dataAraticle.data = data;
    };

    // const updateArticle = (data) => {
    //   newArticle.id = data.id;
    //   newArticle.libelle = data.libelle;
    //   newArticle.categorie = data.categorie;
    //   newArticle.type = data.type;
    //   newArticle.poids = data.poids;
    //   newArticle.unite = data.unite;
    //   newArticle.prix_achat = data.prix_achat;
    //   newArticle.prix_vente = data.prix_vente;
    //   newArticle.movie = data.movie;
    //   newArticle.description = data.description;
    //   root.$bvModal.show("e-edit-article");
    // };
    const EditArticle = async () => {
      if (newArticle.libelle === "") {
        errorInput.path = "libelle";
        errorInput.message = "Veillez entrer un libellé";
      } else if (newArticle.type === "") {
        errorInput.path = "type";
        errorInput.message = "Séléction le type de produit";
      } else if (newArticle.prix_vente === "") {
        errorInput.path = "prix_vente";
        errorInput.message = "Veillez entrer un prix de vente valide";
      } else {
        state.loading = true;

        const config = {
          headers: {
            Accept: "application/json",
          },
        };
        const data = new FormData();
        if (addNewCategorie.value === false) {
          data.append("action", "ancien");

          data.append("category_id", newArticle.categorie.id);
        } else {
          data.append("action", "nouveau");

          data.append("categorie", categorieNewValue.value);
          categories.value.unshift(categorieNewValue.value);
          addNewCategorie.value = false;
        }
        data.append("id", newArticle.id);
        data.append("image", newArticle.file === null ? null : newArticle.file[0]);
        data.append("libelle", newArticle.libelle);
        data.append("type", newArticle.type);
        data.append("prix_vente", newArticle.prix_vente);
        data.append("description", newArticle.description);
        data.append("prix_achat", newArticle.prix_achat);
        data.append("poids", newArticle.poids);
        data.append("lien_video", newArticle.movie);

        await axios
          .post(URL.ARTICLE_UPDATE, data)
          .then((response) => {
            if (response.data) {
              const article = newArticle;
              state.loading = false;

              articles.value.forEach((art) => {
                if (art.id === newArticle.id) {
                  art.libelle = newArticle.libelle;
                  art.categorie =
                    addNewCategorie.value === false
                      ? newArticle.categorie
                      : categorieNewValue.value;
                  art.type = newArticle.type;
                  art.prix_vente = newArticle.prix_vente;
                  art.description = newArticle.description;
                  art.prix_achat = newArticle.prix_achat;
                  art.poids = newArticle.poids;
                  art.movie = newArticle.movie;
                  art.unite = newArticle.unite;
                }
              });
              root.$bvModal.hide("e-edit-article");
              toast_sucess(root, "success", "top-right", "Articles");

              newArticle.libelle = "";
              newArticle.categorie = "";
              newArticle.type = "";
              newArticle.prix_vente = "";
              newArticle.description = "";
              newArticle.prix_achat = "";
              newArticle.poids = "";
              newArticle.movie = "";
              newArticle.unite = "";
              newArticle.file = null;
            }
          })
          .catch((error) => {
            state.loading = false;
            console.log(error);
          });
      }
    };

    //Destry data article
    const deplayToast = ref(0);
    const destoryArticle = async (id) => {
      root
        .$swal({
          title: `Êtes vous sûr !?`,

          text: "Aucun retour en arriére ne sera posible",

          icon: "warning",

          showCancelButton: true,

          confirmButtonText: "Oui",

          customClass: {
            confirmButton: "btn btn-primary",

            cancelButton: "btn btn-outline-danger ml-1",
          },

          buttonsStyling: false,
        })
        .then(async (response) => {
          if (response.isConfirmed === true) {
            deplayToast.value = 500;
            toast_loading(root, deplayToast.value);
            try {
              const { data } = await axios.post(URL.ARTICLE_DESTROY, {
                id: id,
              });
              if (data) {
                articles.value = articles.value.filter((art) => {
                  return art.id !== id;
                });
                deplayToast.value = 0;
                toast_destory(
                  root,
                  "success",
                  "top-right",
                  `Article supprimer avec succès !`
                );
                articles.value.length === 0
                  ? (state.empty = true)
                  : (state.success = true);
              }
            } catch (error) {
              toast_error(
                root,
                "danger",
                "top-right",
                `Oups, un erreur est survenu veillez ressayer...`
              );
            }
          }
        });
    };

    // Formater Money
    // const formatter = new Intl.NumberFormat('de-DE', {
    //   currency: 'XOF',
    //   style: 'currency',
    //   minimumFractionDigits: 2,
    // });

    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

    return {
      state,
      perPageOptions,
      articles,
      dataAraticle,
      getArticles,
      tableColumns,
      categories,
      formatter,
      updateArticle,

      //
      processFile2,
      errorInput,
      EditArticle,
      newArticle,
      addNewCategorie,
      categorieNewValue,
      optionUnite,
      optionType,
      __NEXT,
      getFile,
      onRealTimeData,

      //
      destoryArticle,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.add-new-client-header {
  padding: $options-padding-y $options-padding-x;

  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}

.table-base {
  margin: 30px auto 0;
}

.tableau {
  box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
  width: 100%;

  margin: auto;

  border-radius: 13px;
}

.table-card thead tr {
  border-radius: 13px;

  background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
  background-color: rgb(68, 68, 68) !important;

  color: white;
}

.add-btn {
  position: absolute;

  right: 0;

  top: -50px;

  background-color: #450077;
}

.none {
  display: none;
}

.block {
  display: inline-block;
}

.caticon {
  color: #ff3333;
}

.caticon:hover {
  cursor: pointer;
}
.red:after {
  content: " *";
  color: red;
}
</style>
